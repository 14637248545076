<template>
  <b-row
    align-h="center"
  >
    <!-- invalid QR Code alert -->
    <b-col
      v-if="!QRCodeValid"
      lg="6"
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error
        </h4>
        <div class="alert-body">
          <span>QR Code is invalid.</span>
        </div>
      </b-alert>
    </b-col>

    <b-col
      v-if="QRCodeValid"
      lg="6"
    >
      <b-card>
        <template
          #header
          class="mb-0"
        >
          <span class="ml-1 font-weight-bold">
            <feather-icon
              icon="UserIcon"
            />
            Ticket Details
          </span>
        </template>

        <b-card>
          <b-table
            head-variant="light"
            :items="ticketDetails"
            :fields="columns"
            class="attendee-table"
            responsive
            show-empty
          />
        </b-card>
        <b-button
          variant="primary"
          @click="confirmAttendance"
        >
          Confirm
        </b-button>

        <back />
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'

export default {
  name: 'ConfirmAttendance',
  components: { Back },
  data() {
    return {
      ticketDetails: [],
      columns: [
        { key: 'userName' },
        { key: 'eventName' },
        { key: 'sessionName' },
        { key: 'status' },
        { key: 'eventName' },
      ],
      QRCodeValid: true,
    }
  },
  setup() {
    const { successfulOperationAlert, failedOperationAlert } = handleAlerts()

    return {
      successfulOperationAlert,
      failedOperationAlert,
    }
  },
  created() {
    this.getBookingDetailsByQR()
  },
  methods: {
    getBookingDetailsByQR() {
      this.$activities
        .get(`/internalops/bookings/${this.$route.params.QRCode}/scan`).then(res => {
          this.ticketDetails = [{ ...res.data.data }]
        }).catch(() => {
          this.QRCodeValid = false
        })
    },
    confirmAttendance() {
      return this.$activities
        .post(`/internalops/bookings/${this.$route.params.QRCode}/attend`).then(() => {
          this.successfulOperationAlert('Attendance Verified Successfully')
          this.$router.push({ name: 'quick-event-scan-ticket' })
        })
    },
  },
}
</script>
<style lang="scss">
.attendee-table table thead th {
    background-color: transparent !important;
    border:0 !important;
}
</style>
